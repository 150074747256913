import React, { createContext } from 'react';
import { BrowserRouter as Router, Route, useNavigate } from 'react-router-dom';
import RouteSwitch from './RouteSwitch';
import NotFound from '../views/NotFound';
import Home from '../views/Website/Home';
import DashboardHome from '../views/Dashboard/Home';
import StoreFinder from '../views/Website/StoreFinder';
import AboutUs from '../views/Website/AboutUs';
import ContactUs from '../views/Website/ContactUs';
import TermsConditions from '../views/Website/TermsConditions';
import PaymentMethods from '../views/Website/PaymentMethods';
import TopAmount from '../views/Website/TopAmount';
import Paymentdetail from '../views/Website/Paymentdetail';
import Wishlist from '../views/Website/Wishlist';
import Cart from '../views/Website/Cart';
import Login from '../views/Website/Login';
import Register from '../views/Website/Register';
import Checkout from '../views/Website/Checkout';
import DeliveryOption from '../views/Website/DeliveryOption';
import DashStore from '../views/Dashboard/Stores';
import CreateStore from '../views/Dashboard/CreateStore';
import StoreSettings from '../views/Dashboard/StoreSettings';
import AccountDetails from '../views/Website/AccountDetails';
import OrderDetails from '../views/Website/OrderDetails';
import Shop from '../views/Website/Shop';
import ProductDetail from '../views/Website/ProductDetail';
import Users from '../views/Dashboard/Users/Users.js';
import UserDetails from '../views/Dashboard/Users/UserDetails.js';
import Offer from '../views/Dashboard/Offer/Offer.js';
import AddOffer from '../views/Dashboard/Offer/AddOffer.js';
import OfferDetail from '../views/Dashboard/Offer/OfferDetail.js';
import Orders from '../views/Dashboard/Order/Orders.js';
import OrderDetail from '../views/Dashboard/Order/OrderDetail.js';

import Inquiries from '../views/Dashboard/Contact/Inquiries.js';
import InquiriesDetails from '../views/Dashboard/Contact/InquiriesDetails.js';
import Product from '../views/Dashboard/Product/Product.js';
import ProductDetails from '../views/Dashboard/Product/ProductDetails.js';

import Team from '../views/Dashboard/Team/Team.js';

import ReportAnalytics from '../views/Dashboard/Report/ReportAnalytics.js';
import Profile from '../views/Dashboard/Profile/Profile.js';
import Delivery from '../views/Dashboard/Delivery/Delivery.js';
import Discounts from '../views/Dashboard/Discount/Discounts.js';
import AddDsicount from '../views/Dashboard/Discount/AddDiscount.js';
import DiscountDetails from '../views/Dashboard/Discount/DiscountDetail.js';
import TeamDetail from '../views/Dashboard/Team/TeamDetail.js';
import AddProducts from '../views/Dashboard/Product/AddProducts.js';
import AddNewMember from '../components/Dashboard/Team/AddNewMember.js';
import AddNewTeamMember from '../views/Dashboard/Team/AddNewMember.js';
import MostSellingItem from '../views/Dashboard/Home/MostSellingItem.js';
import BonusCard from '../views/Website/BonusCard.js';
import OurDiscounts from '../views/Website/OurDiscounts.js';
import History from '../views/Website/History.js';
import ForgotPassword from '../views/Website/ForgotPassword.js';
import Verification from '../views/Website/Verification.js';
import NewPassword from '../views/Website/NewPassword.js';
import DiscountProducts from '../views/Website/DiscountProducts.js';
import TopRatedSeller from '../components/Website/toprated/TopRatedSeller.js';
import Arrival from '../components/Website/arrivals/Arrival.js';
import PrivacyPolicy from '../views/Website/PrivacyPolicy.js';
import DeliveryAreas from '../components/Dashboard/Delivery/DeliveryAreas.js';
import { useToast } from '@chakra-ui/react';
import Categories from '../views/Dashboard/Product/Categories';
import Invoice from '../views/Dashboard/Order/Invoice.js';

export const UserContext = createContext();

const PrivateRoute = ({ children }) => {
  const id = 'priv-toast';
  const navigate = useNavigate();
  const toast = useToast();
  let user = JSON.parse(localStorage.getItem('ajwaUser') ?? '{}');
  if (!user.verificationToken) {
    navigate('/login');
    if (!toast.isActive(id)) {
      toast({
        id: id,
        title: 'Please Login First',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }
  return children;
};

export default function AppRoute() {
  return (
    <div>
      <Router>
        <RouteSwitch>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/storefinder" element={<StoreFinder />}></Route>
          <Route exact path="/about" element={<AboutUs />}></Route>
          <Route exact path="/contactus" element={<ContactUs />}></Route>

          <Route
            exact
            path="/termsconditions"
            element={<TermsConditions />}
          ></Route>
          <Route
            exact
            path="/privacypolicy"
            element={<PrivacyPolicy />}
          ></Route>
          <Route exact path="/bonuscard" element={<BonusCard />}></Route>
          <Route
            exact
            path="/paymentmethods"
            element={<PaymentMethods />}
          ></Route>
          <Route exact path="/topupamount" element={<TopAmount />}></Route>
          <Route
            exact
            path="/paymentdetails"
            element={<Paymentdetail />}
          ></Route>
          <Route exact path="/wishlist" element={<Wishlist />}></Route>
          <Route exact path="/shop/:id" element={<Shop />}></Route>
          <Route
            exact
            path="/discountoffer/:id"
            element={<DiscountProducts />}
          ></Route>
          <Route
            exact
            path="/productdetail/:id"
            element={<ProductDetail />}
          ></Route>
          <Route exact path="/cart" element={<Cart />}></Route>
          <Route exact path="/login" element={<Login />}></Route>
          <Route
            exact
            path="/forgotpassword"
            element={<ForgotPassword />}
          ></Route>
          <Route exact path="/verification" element={<Verification />}></Route>
          <Route exact path="/newpassword" element={<NewPassword />}></Route>
          <Route exact path="/signup" element={<Register />}></Route>
          <Route exact path="/checkout" element={<Checkout />}></Route>
          <Route exact path="/discount" element={<OurDiscounts />}></Route>
          <Route
            exact
            path="/deliveryoption"
            element={<DeliveryOption />}
          ></Route>
          <Route exact path="/myacount" element={<AccountDetails />}></Route>
          <Route exact path="/orderhistory" element={<History />}></Route>
          <Route
            exact
            path="/oderdetails/:id"
            element={<OrderDetails />}
          ></Route>
          <Route exact path="/allproducts" element={<TopRatedSeller />}></Route>
          <Route exact path="/new-arrivals" element={<Arrival />}></Route>

          <Route exact path="*" element={<NotFound />}></Route>

          {/* Dashboard Route */}
          <Route
            exact
            path="/dashboard"
            element={
              <PrivateRoute>
                <DashboardHome />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/dashboard/store"
            element={
              <PrivateRoute>
                <DashStore />
              </PrivateRoute>
            }
          ></Route>

          {/* <Route
            exact
            path="/dashboard/store/createbranch"
            element={<CreateStore />}
          ></Route> */}
          <Route
            exact
            path="/dashboard/store/storesetting/:id"
            element={
              <PrivateRoute>
                <StoreSettings />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/dashboard/users"
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/dashboard/userDetails/:id"
            element={
              <PrivateRoute>
                <UserDetails />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/dashboard/orders"
            element={
              <PrivateRoute>
                <Orders />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/dashboard/orderDetail/:id"
            element={
              <PrivateRoute>
                <OrderDetail />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/dashboard/inquiries"
            element={
              <PrivateRoute>
                <Inquiries />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/dashboard/inquiriesdetails/:id"
            element={
              <PrivateRoute>
                <InquiriesDetails />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/dashboard/product"
            element={
              <PrivateRoute>
                <Product />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/dashboard/productdetails/:id"
            element={
              <PrivateRoute>
                <ProductDetails />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/dashboard/check/:id"
            element={
              <PrivateRoute>
                <Invoice />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/dashboard/addproducts"
            element={
              <PrivateRoute>
                <AddProducts />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/dashboard/categories"
            element={
              <PrivateRoute>
                <Categories />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/dashboard/edit/product/:productId"
            element={
              <PrivateRoute>
                <AddProducts />
              </PrivateRoute>
            }
          ></Route>
          {/* Arham */}
          <Route
            path="/dashboard/offers"
            element={
              <PrivateRoute>
                <Offer />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/dashboard/add-offer"
            element={
              <PrivateRoute>
                <AddOffer />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/dashboard/offer-detail/:id"
            element={
              <PrivateRoute>
                <OfferDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/team"
            element={
              <PrivateRoute>
                <Team />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/analytics"
            element={
              <PrivateRoute>
                <ReportAnalytics />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/delivery"
            element={
              <PrivateRoute>
                <Delivery />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/delivery-areas"
            element={
              <PrivateRoute>
                <DeliveryAreas />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/discounts"
            element={
              <PrivateRoute>
                <Discounts />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/add-discount"
            element={
              <PrivateRoute>
                <AddDsicount />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/discount-detail/:id"
            element={
              <PrivateRoute>
                <DiscountDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/team-detail/:id"
            element={
              <PrivateRoute>
                <TeamDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/add-new-member"
            element={
              <PrivateRoute>
                <AddNewTeamMember />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/most-selling-items"
            element={
              <PrivateRoute>
                <MostSellingItem />
              </PrivateRoute>
            }
          />
          {/* Arham */}
        </RouteSwitch>
      </Router>
    </div>
  );
}
