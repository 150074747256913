import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Avatar,
  Spinner,
  Badge,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import { convertNumbertoCommaSeperate } from '../../../utilities/helper';
import Pagination from '../../../utilities/Pagination';
import moment from 'moment';

export default function OrderList() {
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [SelectServices, setSelectServices] = useState('orders');
  const [innerServices, setInnerServices] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 20;

  async function fetchOrder() {
    setIsLoading(true);
    try {
      const storeId = localStorage.getItem('storeId');
      // const response = await GET(`/store/order/?storeId=${storeId}`);
      const response = await GET(
        `/admin/adminOrder/?storeId=${storeId}&type=${SelectServices}&filter=${innerServices}&skip=${
          (currentPage - 1) * itemsPerPage
        }&limit=${itemsPerPage}`
      );
      console.log('Orders', response);
      if (response.status === 'success' && response.data) {
        setSelectedOrder(response.data);
        setTotalItems(response?.count);
        setIsLoading(false);
      } else {
        console.error(
          'Error while fetching Orders:',
          response.message || 'Unknown error'
        );
      }
    } catch (error) {
      console.error('Error while fetching Orders:', error);
    }
  }
  const getStatusColor = status => {
    switch (status) {
      case 'pending':
        return 'yellow';
      case 'inprocess':
        return 'blue';
      case 'completed':
        return 'green';
      case 'cancelled':
        return 'red';
      default:
        return 'gray';
    }
  };
  useEffect(() => {
    fetchOrder();
  }, [currentPage, SelectServices, innerServices]);

  return (
    <>
      <Stack gap={6} px={4} pb={6}>
        <Stack>
          <Heading fontSize={'30px'} fontWeight={'700'}>
            Orders
          </Heading>
          {/* <Text fontSize={'15px'} color={'#1A1B1A'} fontWeight={'400'}>
            Here is the all user details...Check Now!
          </Text> */}
        </Stack>

        <Stack>
          <Stack gap={2}>
            <Heading fontSize={'18px'} fontWeight={'500'}>
              Sort Via Categories:
            </Heading>
            <Stack
              direction={'row'}
              alignItems={'center'}
              gap={4}
              flexWrap={'wrap'}
            >
              <Button
                border={
                  SelectServices === 'orders'
                    ? '2px solid #EAA13E'
                    : '2px solid #07d57c'
                }
                padding={'10px 25px'}
                bg={
                  SelectServices === 'orders'
                    ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                    : 'transparent'
                }
                width={'max-content'}
                _hover={{
                  color: '#000',
                  bg:
                    SelectServices === 'orders'
                      ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                      : 'transparent',
                }}
                onClick={() => {
                  setSelectServices('orders');
                  setInnerServices('');
                  setCurrentPage(1);
                }}
              >
                Orders
              </Button>
              <Button
                border={
                  SelectServices === 'history'
                    ? '2px solid #EAA13E'
                    : '2px solid #07d57c'
                }
                padding={'10px 25px'}
                bg={
                  SelectServices === 'history'
                    ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                    : 'transparent'
                }
                width={'max-content'}
                _hover={{
                  color: '#000',
                  bg:
                    SelectServices === 'history'
                      ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                      : 'transparent',
                }}
                onClick={() => {
                  setSelectServices('history');
                  setInnerServices('');

                  setCurrentPage(1);
                }}
              >
                History
              </Button>
            </Stack>
            {SelectServices === 'orders' && (
              <Stack flexDirection={'row'} alignItems={'center'} gap={4}>
                <Button
                  mt={'0 !important'}
                  border={
                    innerServices === 'pending'
                      ? '2px solid #EAA13E'
                      : '2px solid #07d57c'
                  }
                  padding={'10px 25px'}
                  bg={
                    innerServices === 'pending'
                      ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                      : 'transparent'
                  }
                  width={'max-content'}
                  _hover={{
                    color: '#000',
                    bg:
                      innerServices === 'pending'
                        ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                        : 'transparent',
                  }}
                  onClick={() => {
                    setInnerServices('pending');
                    setCurrentPage(1);
                  }}
                >
                  Pending
                </Button>
                <Button
                  mt={'0 !important'}
                  border={
                    innerServices === 'processing'
                      ? '2px solid #EAA13E'
                      : '2px solid #07d57c'
                  }
                  padding={'10px 25px'}
                  bg={
                    innerServices === 'processing'
                      ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                      : 'transparent'
                  }
                  width={'max-content'}
                  _hover={{
                    color: '#000',
                    bg:
                      innerServices === 'processing'
                        ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                        : 'transparent',
                  }}
                  onClick={() => {
                    setInnerServices('processing');
                    setCurrentPage(1);
                  }}
                >
                  Processing
                </Button>
                <Button
                  mt={'0 !important'}
                  border={
                    innerServices === 'shipped'
                      ? '2px solid #EAA13E'
                      : '2px solid #07d57c'
                  }
                  padding={'10px 25px'}
                  bg={
                    innerServices === 'shipped'
                      ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                      : 'transparent'
                  }
                  width={'max-content'}
                  _hover={{
                    color: '#000',
                    bg:
                      innerServices === 'shipped'
                        ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                        : 'transparent',
                  }}
                  onClick={() => {
                    setInnerServices('shipped');
                    setCurrentPage(1);
                  }}
                >
                  Shipped
                </Button>
              </Stack>
            )}
            {SelectServices === 'history' && (
              <Stack flexDirection={'row'} alignItems={'center'} gap={4}>
                <Button
                  mt={'0 !important'}
                  border={
                    innerServices === 'complete'
                      ? '2px solid #EAA13E'
                      : '2px solid #07d57c'
                  }
                  padding={'10px 25px'}
                  bg={
                    innerServices === 'complete'
                      ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                      : 'transparent'
                  }
                  width={'max-content'}
                  _hover={{
                    color: '#000',
                    bg:
                      innerServices === 'complete'
                        ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                        : 'transparent',
                  }}
                  onClick={() => {
                    setInnerServices('complete');
                    setCurrentPage(1);
                  }}
                >
                  Completed
                </Button>
                <Button
                  mt={'0 !important'}
                  border={
                    innerServices === 'cancelled'
                      ? '2px solid #EAA13E'
                      : '2px solid #07d57c'
                  }
                  padding={'10px 25px'}
                  bg={
                    innerServices === 'cancelled'
                      ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                      : 'transparent'
                  }
                  width={'max-content'}
                  _hover={{
                    color: '#000',
                    bg:
                      innerServices === 'cancelled'
                        ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                        : 'transparent',
                  }}
                  onClick={() => {
                    setInnerServices('cancelled');
                    setCurrentPage(1);
                  }}
                >
                  Cancelled
                </Button>
              </Stack>
            )}
          </Stack>
          {isLoading ? (
            <Box
              display={'flex'}
              height={'50vh'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Spinner color="#47A046" />
            </Box>
          ) : (
            <Stack gap={2}>
              <SimpleGrid
                columns={{ base: '1', md: '1', xl: '2', '2xl': '2' }}
                spacing={10}
              >
                {selectedOrder?.length > 0 ? (
                  selectedOrder?.map((v, k) => (
                    <Stack
                      key={k}
                      borderRadius={8}
                      gap={2}
                      p={5}
                      boxShadow="xl"
                    >
                      <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        flexWrap={'wrap'}
                        alignItems={'center'}
                      >
                        <Text
                          color={'#1A1B1A'}
                          fontSize={'16px'}
                          fontWeight={600}
                        >
                          Order Id:{' '}
                          <Text as={'span'} color={'#007341'}>
                            {v?.invoice}{' '}
                          </Text>
                        </Text>
                        <Text
                          color={'#1A1B1A'}
                          fontSize={'16px'}
                          fontWeight={600}
                          display={'flex'}
                          alignItems={'center'}
                          gap={2}
                        >
                          Status:{' '}
                          <Box>
                            <Badge
                              fontSize={'12px'}
                              fontWeight={600}
                              border={`1px solid ${getStatusColor(
                                v?.orderStatus
                              )}`}
                              borderRadius={'10px'}
                              size={'lg'}
                              colorScheme={getStatusColor(v?.orderStatus)}
                            >
                              {v?.orderStatus}
                            </Badge>
                          </Box>
                        </Text>
                      </Box>

                      <Box>
                        <SimpleGrid
                          alignItems={'center'}
                          columns={2}
                          spacing={10}
                        >
                          <Box display={'flex'} gap={2} alignItems={'center'}>
                            <Avatar
                              size="lg"
                              name={v?.userId?.fullName}
                              src={`${imgUrl}${v?.userId?.profile_picture}`}
                            />
                            <Box>
                              <Heading
                                color={'#111'}
                                fontWeight={700}
                                fontSize={'16px'}
                                textTransform={'capitalize'}
                              >
                                {v?.isGuest ? v?.name : v?.userId?.fullName}
                              </Heading>
                              <Text
                                color={'#1A1B1A'}
                                fontSize={'12px'}
                                fontWeight={600}
                              >
                                {v?.email}
                              </Text>
                            </Box>
                          </Box>
                          <Stack justifySelf={'end'}>
                            <Button
                              as={Link}
                              bg={
                                'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                              }
                              border={'2px solid #07d57c'}
                              padding={'10px 20px'}
                              fontSize={'15px'}
                              width={'max-content'}
                              to={`/dashboard/orderDetail/${v?._id}`}
                            >
                              Order Details
                            </Button>
                          </Stack>
                        </SimpleGrid>
                      </Box>
                      <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        flexWrap={'wrap'}
                        alignItems={'center'}
                      >
                        <Box>
                          <Heading color={'#007341'} fontSize={'16px'}>
                            Price
                          </Heading>
                          <Text
                            color={'#1A1B1A'}
                            fontSize={'16px'}
                            fontWeight={600}
                          >
                            Rs : {convertNumbertoCommaSeperate(v.total)}
                          </Text>
                        </Box>
                        <Box>
                          <Heading color={'#007341'} fontSize={'16px'}>
                            Quantity
                          </Heading>
                          <Text
                            color={'#1A1B1A'}
                            fontSize={'16px'}
                            fontWeight={600}
                          >
                            {v.qauntity}
                          </Text>
                        </Box>
                        <Box>
                          <Heading color={'#007341'} fontSize={'16px'}>
                            Purchased Date
                          </Heading>
                          <Text
                            color={'#1A1B1A'}
                            fontSize={'16px'}
                            fontWeight={600}
                          >
                            {moment(v?.orderDate).format('DD-MMM-YYYY')}
                          </Text>
                        </Box>
                      </Box>
                    </Stack>
                  ))
                ) : (
                  <Text
                    color={'#1A1B1A'}
                    fontSize={'18px'}
                    mt={6}
                    fontWeight={600}
                  >
                    No Orders Found
                  </Text>
                )}
              </SimpleGrid>
              {totalItems > itemsPerPage && (
                <Stack bg={'#fff'} px={4} py={4} borderRadius={12} mt={4}>
                  <Pagination
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    onPageChange={setCurrentPage}
                  />
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
}
