import React, { useEffect, useState } from 'react';
import Header from '../Header';
import MiniBanner from '../banner/MiniBanner';
import {
  Box,
  Stack,
  Container,
  Heading,
  Button,
  Text,
  SimpleGrid,
  Tag,
  Flex,
  Image,
  Icon,
  Spinner,
} from '@chakra-ui/react';
import Footer from '../Footer';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';
import { FiShoppingCart } from 'react-icons/fi';
import { imgUrl } from '../../../utilities/config';
import star from '../../../assets/images/website/star.svg';
import {
  convertNumbertoCommaSeperate,
  useAddToCart,
  useAddToWhishlist,
} from '../../../utilities/helper';
import Pagination from '../../../utilities/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import ReactStars from 'react-rating-stars-component';
function Arrival() {
  const [productlist, setProductlist] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 20;
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(state => state.user.value);

  const { addToCart, loading: isLoading2 } = useAddToCart();
  const { handleFavourite, loading } = useAddToWhishlist();
  useEffect(() => {
    const storeId = localStorage.getItem('storeId');
    // console.log(currentPage * itemsPerPage, itemsPerPage);
    async function fetchProducts() {
      setIsLoading(true);
      try {
        const response = await GET(
          `/store/product?storeId=${storeId}&skip=${
            (currentPage - 1) * itemsPerPage
          }&limit=${itemsPerPage}`
        );
        window.scrollTo({ top: 0, behavior: 'smooth' });

        console.log(response);
        if (response.status === 'success') {
          setIsLoading(false);
          setProductlist(response.data || []);
          setTotalItems(response.count);
        } else {
          setIsLoading(false);
          console.log('Error while fetching products');
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error, 'error while fetching products');
      }
    }
    fetchProducts();
  }, [currentPage]);
  return (
    <>
      <Header />
      <MiniBanner title={'New Arrivals'} breadtitle={'Our New Arrivals'} />
      <Stack>
        <Container p={0} mb={6} maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}>
          <Stack mb={6}>
            {!productlist ? (
              <Stack
                bg={'#fff'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                minH={'400px'}
              >
                <Spinner />
              </Stack>
            ) : (
              <SimpleGrid
                bg={'#fff'}
                columns={{ base: '2', sm: '3', lg: '4', xl: '5', '2xl': '5' }}
                spacing={0}
              >
                {productlist?.length > 0 &&
                  productlist.map((v, k) => {
                    return (
                      <Stack key={k} border={'1px solid #D9E8D6'}>
                        <Stack as={Link} to={`/productdetail/${v._id}`}>
                          <Stack position={'relative'}>
                            {((v.off && v.off > 0) ||
                              (v.stock && v.stock !== 0)) && (
                              <Box
                                display={'flex'}
                                gap={2}
                                position={'absolute'}
                                top={'5px'}
                                left={'5px'}
                              >
                                {v.off > 0 && (
                                  <Tag
                                    bg={'#007341'}
                                    lineHeight={'base'}
                                    color={'#fff'}
                                    fontSize={'12px'}
                                    fontWeight={600}
                                  >
                                    {v.off} %
                                  </Tag>
                                )}
                                {v.stock && (
                                  <Tag
                                    bg={v.stock > 0 ? '#E0883B' : '#C5262F'}
                                    lineHeight={'base'}
                                    color={'#fff'}
                                    fontSize={'12px'}
                                    fontWeight={600}
                                  >
                                    {v.stock > 0 ? 'In Stock' : 'Out of Stock'}
                                  </Tag>
                                )}
                              </Box>
                            )}
                            <Image
                              minHeight={'238px'}
                              maxHeight={'238px'}
                              objectFit={'cover'}
                              src={
                                v?.url?.includes('https')
                                  ? v?.url
                                  : `${imgUrl}${v?.url}`
                              }
                            />
                          </Stack>
                          <Stack className="mh-3" px={4} py={3} spacing={2}>
                            <Stack>
                              <Heading
                                as={'h3'}
                                noOfLines={1}
                                color={'#212121'}
                                display={'-webkit-box'}
                                WebkitLineClamp={2}
                                WebkitBoxOrient={'vertical'}
                              >
                                {v.name}
                              </Heading>
                              <Text
                                fontSize={'13px'}
                                minHeight={'40px'}
                                noOfLines={[1, 2]}
                                color={'#212121'}
                                overflow={'hidden'}
                                display={'-webkit-box'}
                                WebkitLineClamp={2}
                                WebkitBoxOrient={'vertical'}
                              >
                                {v.description}
                              </Text>
                            </Stack>
                            <Box display={'flex'} gap={2}>
                              <ReactStars
                                count={5}
                                value={v.rating}
                                size={15}
                                isHalf={true}
                                edit={false}
                                activeColor="#ffd700"
                              />
                              <Text fontSize={'12px'} color={'#989BA2'}>
                                ({v.rating})
                              </Text>
                            </Box>
                          </Stack>
                        </Stack>
                        <Stack px={4} pb={4} spacing={2}>
                          <Text
                            fontSize={'13px'}
                            minHeight={'40px'}
                            noOfLines={[1, 2]}
                            color={'#212121'}
                          >
                            {v.tagName}
                          </Text>
                          <Box
                            className="mh-3"
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                          >
                            <Stack>
                              {v?.isDiscount ? (
                                <Heading as={'h3'}>
                                  Rs {v.discountedPrice}
                                  <Text
                                    as="span"
                                    ml={2}
                                    textDecoration="line-through"
                                    color="gray.500"
                                    fontSize={'sm'}
                                  >
                                    Rs {convertNumbertoCommaSeperate(v.price)}
                                  </Text>
                                </Heading>
                              ) : (
                                <Heading as={'h3'}>
                                  Rs {convertNumbertoCommaSeperate(v.price)}
                                </Heading>
                              )}
                            </Stack>
                            <Flex gap={2}>
                              {user && (
                                <Button
                                  disabled={loading}
                                  onClick={() => {
                                    handleFavourite(v, false);
                                    if (loading === false) {
                                      setProductlist(
                                        productlist.map(item => {
                                          if (item._id === v._id) {
                                            return {
                                              ...item,
                                              is_favourite: !item.is_favourite,
                                            };
                                          }
                                          return item;
                                        })
                                      );
                                    }
                                  }}
                                  w={'40px'}
                                  height={'40px'}
                                  borderRadius={'50%'}
                                >
                                  <Icon
                                    fontSize={'15px'}
                                    color={'#007341'}
                                    as={v?.is_favourite ? FaHeart : FaRegHeart}
                                  />
                                </Button>
                              )}

                              <Button
                                disabled={
                                  v.stock <= 0 || isLoading ? true : false
                                }
                                onClick={() => addToCart(v)}
                                w={'40px'}
                                height={'40px'}
                                borderRadius={'50%'}
                              >
                                <Icon
                                  fontSize={'15px'}
                                  color={'#007341'}
                                  as={FiShoppingCart}
                                />
                              </Button>
                            </Flex>
                          </Box>
                        </Stack>
                      </Stack>
                    );
                  })}
              </SimpleGrid>
            )}
          </Stack>

          {totalItems > itemsPerPage && (
            <Stack bg={'#fff'} px={4} py={4} borderRadius={12} gap={8}>
              <Pagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
                isLoading={isLoading}
              />
            </Stack>
          )}
        </Container>
      </Stack>
      <Footer />
    </>
  );
}

export default Arrival;
