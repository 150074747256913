import {
  Avatar,
  Box,
  Button,
  Grid,
  Heading,
  Icon,
  Image,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { LuUser } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';
import moment from 'moment';

function Discounts() {
  const [data, setData] = useState(null);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await GET(
          '/store/discount?storeId=66792cc177d1c268a6bf34af&currentDate=2024-06-27'
        );
        console.log('Discounts', response);
        if (response.status === 'success') {
          setData(response.data?.discounts);
        } else {
          setData([]);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  function calculateAvailability(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const differenceInMs = end - start;

    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

    return differenceInDays;
  }
  return (
    <Stack px={6} py={2}>
      <Stack
        mb={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Stack display={'flex'} flexDirection={'column'}>
          <Heading fontSize={'30px'} fontWeight={'700'}>
            Discounts
          </Heading>
          {/* <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the all discounts...Check Now!
          </Text> */}
          <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
            You’ve {data?.length} discounts
          </Text>
        </Stack>
        <Button
          as={Link}
          to={'/dashboard/add-discount'}
          padding={'10px 30px'}
          bg={
            'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
          }
          color={'#fff'}
          fontWeight={'600'}
          lineHeight={'inherit'}
          fontSize={'15px'}
          borderRadius={6}
          _hover={{
            color: '#000',
          }}
          w={'fit-content'}
        >
          Add New Discounts
        </Button>
      </Stack>
      {!data ? (
        <Stack
          w={'100%'}
          h={'60vh'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Spinner color="#47A046" />
        </Stack>
      ) : data.length <= 0 ? (
        <Heading fontSize={'26px'}>No Discounts Found</Heading>
      ) : (
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          {data.map(item => (
            <Stack
              gap={2}
              shadow={'0px 16.32px 38.75px 0px #0000000F'}
              borderRadius={'17px'}
              p={{ base: '15px 20px', xl: '25px 40px' }}
              role="group"
              transition={'0.3s'}
              _hover={{
                borderColor: 'primaryGreen.200',
              }}
            >
              <Box display={'flex'} justifyContent={'space-between'}>
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  <Box>
                    <Avatar
                      size="lg"
                      name={item?.name || 'Big Sale'}
                      src={item?.image}
                    />
                  </Box>
                  <Box>
                    <Heading
                      fontSize={'16px'}
                      fontWeight={'700'}
                      color={'#007341'}
                      lineHeight={'1.6rem'}
                    >
                      {item?.name || 'Big Sale'}
                    </Heading>
                    <Text
                      color={'#989BA2'}
                      fontSize={'14px'}
                      fontWeight={'400'}
                    >
                      {moment(item.createdAt).fromNow()}
                    </Text>
                  </Box>
                </Stack>
                <Stack alignItems={'end'} justifyContent={'center'}>
                  <Button
                    as={Link}
                    to={`/dashboard/discount-detail/${item._id}`}
                    border={'2px solid #07d57c'}
                    padding={'10px 25px'}
                    bg={'transparent'}
                    width={'fit-content'}
                    fontSize={'15px'}
                    borderRadius={6}
                  >
                    View Details
                  </Button>
                </Stack>
              </Box>
              <Stack
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Stack display={'flex'} flexDirection={'column'}>
                  <Text fontSize={'17px'} fontWeight={'500'} color={'#1A1B1A'}>
                    Discount
                  </Text>
                  <Text
                    fontSize={'18px'}
                    fontWeight={'700'}
                    color={'#1A1B1A'}
                    margin={'0 !important'}
                  >
                    {item?.price}
                    {item?.unit || '10'} off
                  </Text>
                </Stack>
                <Stack display={'flex'} flexDirection={'column'}>
                  <Text fontSize={'17px'} fontWeight={'500'} color={'#1A1B1A'}>
                    No. of Users
                  </Text>
                  <Text
                    fontSize={'18px'}
                    fontWeight={'700'}
                    color={'#1A1B1A'}
                    margin={'0 !important'}
                  >
                    {item?.numberOfUser || '25'}
                  </Text>
                </Stack>
                <Stack display={'flex'} flexDirection={'column'}>
                  <Text fontSize={'17px'} fontWeight={'500'} color={'#1A1B1A'}>
                    Availability
                  </Text>
                  <Text
                    fontSize={'18px'}
                    fontWeight={'700'}
                    color={'#1A1B1A'}
                    margin={'0 !important'}
                  >
                    {calculateAvailability(item?.start, item?.end) || '10 days'}{' '}
                    days
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Grid>
      )}
    </Stack>
  );
}

export default Discounts;
