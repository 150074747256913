import React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useDisclosure,
  Flex,
  Icon,
  Stack,
  Heading,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

const DeleteModal = ({ isOpen, onClose, onConfirm, description }) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Stack gap={4} py={4} textAlign={'center'} alignItems={'center'}>
            <Flex justifyContent="center" mb={2}>
              <Icon as={CheckCircleIcon} w={12} h={12} color="green.400" />
            </Flex>
            <Stack>
              <Heading fontSize={'30px'}>Are You Sure!</Heading>
              <Text fontSize={'16px'}>{description}</Text>
            </Stack>
            <Stack w={'100%'} direction={'row'} gap={2}>
              <Button
                py={6}
                fontSize={'16px'}
                w={'100%'}
                fontWeight={500}
                color={'#fff'}
                // background={
                //   'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                // }
                bg={'crimson'}
                _hover={{
                  color: '#fff',
                }}
                onClick={onConfirm}
              >
                Remove
              </Button>
              {/* <Button
                onClick={onClose}
                py={6}
                fontSize={'16px'}
                width={'200px'}
                fontWeight={500}
                color={'#fff'}
                background={
                  'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                }
                _hover={{
                  color: '#fff',
                }}
              >
                Cancel
              </Button> */}
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default DeleteModal;
