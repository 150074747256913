import {
  Box,
  Container,
  ListItem,
  Stack,
  UnorderedList,
  Link,
  Image,
  Text,
  Icon,
  Heading,
} from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import Ftlogo from '../../../assets/images/website/ft-black.png';
import google from '../../../assets/images/website/googles.png';
import apple from '../../../assets/images/website/apple.png';
import visa from '../../../assets/images/website/visa.jpg';
import jazz from '../../../assets/images/website/jazz.jpg';
import easy from '../../../assets/images/website/easy.jpg';
import {
  FaSquareFacebook,
  FaInstagram,
  FaWhatsapp,
  FaPhone,
} from 'react-icons/fa6';
import { GrLocation } from 'react-icons/gr';
import { BiSupport, BiTime } from 'react-icons/bi';
import { IoCheckmarkSharp } from 'react-icons/io5';

export default function Footer() {
  const ftlink = [
    {
      name: 'About Us',
      url: '/about',
    },
    {
      name: 'Contact Us',
      url: '/contactus',
    },
    {
      name: 'Store Finder',
      url: '/storefinder',
    },
    {
      name: 'Terms and Conditions',
      url: '/termsconditions',
    },
    {
      name: 'Privacy Policy',
      url: '/privacypolicy',
    },
  ];

  return (
    <Stack>
      <Container
        bg={'#fff'}
        py={16}
        borderRadius={'12px 12px 0 0 '}
        maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}
        background={
          'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
        }
      >
        <Stack
          px={{ base: '0', sm: '0', lg: '12', xl: '12', '2xl': '12' }}
          direction={'row'}
          flexWrap={'wrap'}
          spacing={{ base: '0', sm: '0', lg: 4, xl: 6, '2xl': 6 }}
          alignItems={'center'}
        >
          <Stack
            w={{ base: '100%', sm: '15%', lg: '20%', xl: '20%', '2xl': '20%' }}
            spacing={2}
          >
            <Heading
              fontSize={{
                base: '15px',
                sm: '16px',
                lg: '18px',
                xl: '19px',
                '2xl': '20px',
              }}
            >
              Useful Links
            </Heading>
            <UnorderedList spacing={3} m={0}>
              {ftlink?.length > 0 &&
                ftlink.map((v, k) => {
                  return (
                    <ListItem listStyleType={'none'}>
                      <Link
                        display={'flex'}
                        alignItems={'center'}
                        gap={2}
                        key={k}
                        as={ReactLink}
                        to={v.url}
                      >
                        <Icon as={IoCheckmarkSharp} />
                        <Text
                          fontWeight={600}
                          fontSize={{
                            base: '12px',
                            sm: '12px',
                            lg: '13px',
                            xl: '14px',
                            '2xl': '15px',
                          }}
                        >
                          {v.name}
                        </Text>
                      </Link>
                    </ListItem>
                  );
                })}
            </UnorderedList>
          </Stack>
          <Stack
            w={{ base: '100%', sm: '55%', lg: '53%', xl: '53%', '2xl': '53%' }}
            pb={{ base: '8', '2xl': '0' }}
            px={{ base: '0', md: '6', xl: '6', '2xl': '6' }}
          >
            <Stack
              textAlign={{
                base: 'left',
                md: 'center',
                xl: 'center',
                '2xl': 'center',
              }}
              alignItems={{
                base: 'start',
                md: 'center',
                xl: 'center',
                '2xl': 'center',
              }}
              spacing={4}
            >
              <Image w={'100px'} src={Ftlogo} />
              <Text
                fontWeight={600}
                fontSize={{
                  base: '12px',
                  sm: '12px',
                  lg: '13px',
                  xl: '14px',
                  '2xl': '15px',
                }}
              >
                Ajwa Super Mart in Phase 4, DHA Karachi, is a premier grocery
                store known for its wide selection of high-quality products.
                Offering everything from fresh produce and dairy to pantry
                staples and household goods, it's a convenient and reliable
                destination for all your shopping needs. The store prides itself
                on exceptional customer service and a clean, well-organized
                shopping environment.
              </Text>
              <UnorderedList display={'flex'} alignItems={'center'} gap={4}>
                <ListItem listStyleType={'none'}>
                  <Link
                    target="_blank"
                    as={'a'}
                    href={'https://www.facebook.com/ajwasupermartkhi/'}
                  >
                    <Icon fontSize={'20px'} as={FaSquareFacebook} />
                  </Link>
                </ListItem>
                <ListItem listStyleType={'none'}>
                  <Link
                    target="_blank"
                    as={'a'}
                    href={
                      'https://www.instagram.com/ajwasupermartdha/?utm_source=qr&igsh=MTVrMW5nMjZxOHgzcA'
                    }
                  >
                    <Icon fontSize={'20px'} as={FaInstagram} />
                  </Link>
                </ListItem>
                <ListItem listStyleType={'none'}>
                  <Link
                    as={'a'}
                    href={`https://wa.me/923302538519`}
                    target="_blank"
                  >
                    <Icon fontSize={'20px'} as={FaWhatsapp} />
                  </Link>
                </ListItem>
              </UnorderedList>
              <Stack spacing={2}>
                <Heading
                  fontSize={{
                    base: '15px',
                    sm: '16px',
                    lg: '18px',
                    xl: '18px',
                    '2xl': '20px',
                  }}
                >
                  Download App on Mobile
                </Heading>
                {/* <Text>15% discount on your first purchase</Text> */}
                <UnorderedList display={'flex'} alignItems={'center'} gap={4}>
                  <ListItem listStyleType={'none'}>
                    <Link
                      as={'a'}
                      target="_blank"
                      // href={
                      //   'https://play.google.com/store/apps/details?id=com.ajwa.mart&pli=1'
                      // }
                      href={
                        'https://play.google.com/store/apps/details?id=com.ajwa.app'
                      }
                    >
                      <Image w={'132px'} borderRadius={6} src={google} />
                    </Link>
                  </ListItem>
                  <ListItem listStyleType={'none'}>
                    <Link
                      as={'a'}
                      target="_blank"
                      // as={ReactLink}
                      // href={
                      //   'https://apps.apple.com/pk/app/ajwa-mart/id1640109496'
                      // }
                      href={
                        'https://apps.apple.com/us/app/ajwa-supermart/id6557061896'
                      }
                    >
                      <Image w={'132px'} borderRadius={6} src={apple} />
                    </Link>
                  </ListItem>
                </UnorderedList>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            w={{ base: '100%', sm: '30%', lg: '20%', xl: '20%', '2xl': '20%' }}
            spacing={4}
          >
            <Stack>
              <Heading
                fontSize={{
                  base: '15px',
                  sm: '16px',
                  lg: '18px',
                  xl: '19px',
                  '2xl': '20px',
                }}
              >
                Contact Us
              </Heading>
              <UnorderedList alignItems={'center'} spacing={2}>
                <ListItem display={'flex'} gap={2} listStyleType={'none'}>
                  <Icon fontSize={'20px'} as={GrLocation} />
                  <Text
                    fontWeight={'600'}
                    fontSize={{
                      base: '12px',
                      sm: '12px',
                      lg: '13px',
                      xl: '14px',
                      '2xl': '15px',
                    }}
                  >
                    28C Khayaban-e-Bahria, D.H.A. Phase 4 , Karachi
                  </Text>
                </ListItem>
                <ListItem gap={2} display={'flex'} listStyleType={'none'}>
                  <Icon fontSize={'20px'} as={BiTime} />
                  <Text
                    fontWeight={'600'}
                    fontSize={{
                      base: '12px',
                      sm: '12px',
                      lg: '13px',
                      xl: '14px',
                      '2xl': '15px',
                    }}
                  >
                    8:00 AM - 3:00 AM
                  </Text>
                  {/* <Text fontWeight={'600'}>Mon-Fri: 10:00 - 18:00</Text> */}
                </ListItem>
                <ListItem gap={2} display={'flex'} listStyleType={'none'}>
                  <Icon fontSize={'20px'} as={BiSupport} />
                  <Text
                    as={'a'}
                    href={'tel:02135802592'}
                    fontWeight={'600'}
                    fontSize={{
                      base: '12px',
                      sm: '12px',
                      lg: '13px',
                      xl: '14px',
                      '2xl': '15px',
                    }}
                  >
                    02135802592
                  </Text>
                </ListItem>
                <ListItem gap={2} display={'flex'} listStyleType={'none'}>
                  <Icon fontSize={'20px'} as={FaPhone} />
                  <Text
                    as={'a'}
                    href={`https://wa.me/923302538519`}
                    target="_blank"
                    fontWeight={'600'}
                    fontSize={{
                      base: '12px',
                      sm: '12px',
                      lg: '13px',
                      xl: '14px',
                      '2xl': '15px',
                    }}
                  >
                    0330-2538519
                  </Text>
                </ListItem>
              </UnorderedList>
            </Stack>
            <Stack>
              <UnorderedList display={'flex'} alignItems={'center'} gap={2}>
                <ListItem listStyleType={'none'}>
                  <Image src={visa} />
                </ListItem>
                <ListItem listStyleType={'none'}>
                  <Image src={jazz} />
                </ListItem>
                <ListItem listStyleType={'none'}>
                  <Image src={easy} />
                </ListItem>
              </UnorderedList>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
}
