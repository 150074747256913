import {
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Link,
  Box,
  Image,
  Text,
  Tag,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { discount } from '../../../assets/data/Data';
import { GET } from '../../../utilities/ApiProvider';
import { baseURL, imgUrl } from '../../../utilities/config';
import { useSelector } from 'react-redux';
import { selectWebsiteData } from '../../../reducers/slices/website.slice';

export default function Discount() {
  const websiteData = useSelector(selectWebsiteData);
  const discountlist = useMemo(() => {
    return websiteData.discount;
  }, [websiteData.discount]);
  console.log(discountlist);
  // useEffect(() => {
  //   console.log(websiteData);
  // }, [websiteData]);

  return (
    <>
      {discountlist?.length > 0 && (
        <Stack>
          <Container
            mb={4}
            px={{ base: '15px', md: '15px', xl: '0', '2xl': '0' }}
            borderRadius={12}
            maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}
          >
            <Box
              mb={6}
              display={'flex'}
              flexWrap={'wrap'}
              justifyContent={'space-between'}
              alignItems={'center'}
              className="mh-2 "
              gap={3}
            >
              <Heading as={'h2'}>Don't Miss our Discounts</Heading>
              <Link
                color={'#243F2F'}
                border={'1px solid #243F2F'}
                borderRadius={'25px'}
                fontWeight={'700'}
                fontSize={{ base: '14px', '2xl': '16px' }}
                w={'max-content'}
                p={{ base: '10px 16px', '2xl': '10px 20px' }}
                to={'/discount'}
                as={ReactLink}
              >
                Show All
              </Link>
            </Box>
            <SimpleGrid
              columns={{ base: '2', sm: '3', lg: '3', xl: '6', '2xl': '6' }}
              spacing={2}
            >
              {discountlist?.length > 0 &&
                discountlist?.slice(0, 6)?.map((v, k) => {
                  return (
                    <Stack
                      as={ReactLink}
                      to={`/discountoffer/${v._id}`}
                      key={k}
                      bg={'#fff'}
                      borderRadius={12}
                      overflow={'hidden'}
                      boxShadow="lg"
                    >
                      <Stack>
                        <Image
                          height={'144px'}
                          objectFit={'cover'}
                          src={
                            v.image?.includes('https')
                              ? v.image
                              : `${imgUrl}/${v.image}`
                          }
                        />
                      </Stack>

                      <Stack
                        className="mh-3"
                        mt={'0 !important'}
                        px={4}
                        py={4}
                        spacing={2}
                      >
                        <Tag
                          width={'max-content'}
                          mt={'-32px'}
                          mb={2}
                          bg={
                            'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                          }
                          py={2}
                          px={2}
                          borderRadius={12}
                          color={'#fff'}
                          fontWeight={600}
                          fontSize={'12px'}
                        >
                          {v.price}% Discount
                        </Tag>
                        <Heading noOfLines={[1, 2]} as={'h3'}>
                          {v.name}
                        </Heading>
                        <Text
                          noOfLines={[1, 2]}
                          fontSize={'13px'}
                          color={'#212121'}
                        >
                          {v.description}
                        </Text>
                      </Stack>
                    </Stack>
                  );
                })}
            </SimpleGrid>
          </Container>
        </Stack>
      )}
    </>
  );
}
