import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Image,
  Button,
  Flex,
} from '@chakra-ui/react';
import { useLocation, useParams } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';
import { convertDate } from '../../../utilities/helper';
import Logo from '../../../assets/images/website/logo1.png';

const Invoice = () => {
  const { id } = useParams();
  const storeId = localStorage.getItem('storeId');
  const [Orderdetails, setOrderdetails] = useState(null);
  async function fetchOrderDetails() {
    try {
      const response = await GET(`/store/order/${id}?storeId=${storeId}`);
      console.log('Order Details', response);

      if (response.status === 'success') {
        setOrderdetails(response.data);
        setTimeout(() => {
          window.print();
          // print close action
          const handleAfterPrint = () => {
            window.close();
          };

          window.addEventListener('afterprint', handleAfterPrint);
          window.close();
        }, 1000);
      } else {
        console.log('Error while fetching products');
      }
    } catch (error) {
      console.log(error, 'error while fetching products');
    }
  }
  const formatTime = dateString => {
    const date = new Date(dateString);
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return date.toLocaleTimeString([], options);
  };

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  return (
    <Box bg="gray.100">
      <Box
        bg="white"
        p={8}
        borderRadius="md"
        h="100vh"
        boxShadow="md"
        // width={{ base: 'full', lg: '60%' }}
        // mx="auto"
      >
        {/* Header */}
        <Flex justify="space-between" align="center" mb={5}>
          <Box>
            <Image src={Logo} alt="logo" width="100px" />
          </Box>
          <Box>
            <Text>
              <b>Invoice No:</b> {Orderdetails?.invoice}
            </Text>
          </Box>
        </Flex>

        {/* Customer Info */}
        <Box mb={5}>
          <Text fontSize="xl" fontWeight="bold" mb={2}>
            Customer Info
          </Text>
          <Text>
            <b>Name: </b>
            {Orderdetails?.name}
          </Text>
          <Text>
            <b>Email:</b> {Orderdetails?.email}
          </Text>
          <Text>
            <b>Address: </b> {Orderdetails?.streetAddress}
          </Text>
        </Box>

        {/* Order Details */}
        <Box mb={5}>
          <Text fontSize="xl" fontWeight="bold" mb={2}>
            Order Info
          </Text>
          <Flex justify="space-between">
            <Text>
              <b>Purchased Date:</b> {convertDate(Orderdetails?.orderDate)}
            </Text>
            <Text>
              <b>Order Time:</b>{' '}
              {Orderdetails?.orderDate
                ? formatTime(Orderdetails.orderDate)
                : 'N/A'}
            </Text>
            <Text>
              <b>Delivery Date:</b> {convertDate(Orderdetails?.deliveryDate)}
            </Text>
            <Text>
              <b>Order Status:</b> {Orderdetails?.orderStatus}
            </Text>
          </Flex>
        </Box>

        {/* Product Table */}
        <Box mb={5}>
          <Table variant="simple">
            <Thead bg="orange.400">
              <Tr>
                <Th color="white">Product Name</Th>
                <Th color="white">Quantity</Th>
                <Th color="white">SKU</Th>
                <Th color="white">Price</Th>
                <Th color="white">Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {/* <Tr>
                <Td>01</Td>
                <Td>Klumpy Cat Litter 5L Super</Td>
                <Td>4</Td>
                <Td>321176</Td>
                <Td>0</Td>
                <Td>500</Td>
              </Tr> */}
              {Orderdetails?.orderDetails?.map((v, k) => {
                return (
                  <Tr key={k}>
                    <Td>{v?.productId?.name}</Td>
                    <Td>{v?.quantity}</Td>
                    <Td>{v?.productId?.sku}</Td>
                    <Td>{v?.price}</Td>
                    <Td>{v?.price * v?.quantity}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>

        {/* Delivery Details */}
        <Box mb={5}>
          <Text fontSize="xl" fontWeight="bold" mb={2}>
            Delivery Details:
          </Text>
          <Text>
            <b>Delivery Date:</b> {convertDate(Orderdetails?.deliveryDate)}
          </Text>
          <Text>
            <b>Delivery Time Slot:</b>{' '}
            {`${Orderdetails?.deliveryId?.day} ${Orderdetails?.deliveryId?.time?.start} - ${Orderdetails?.deliveryId?.time?.end}`}
          </Text>
          <Text>
            <b>Delivery Area:</b> {Orderdetails?.area}
          </Text>
          <Text>
            <b>Payment Method:</b>{' '}
            {Orderdetails?.paymentMethod === 'cod'
              ? 'Cash on Delivery'
              : Orderdetails?.paymentMethod}
          </Text>
          <Text>
            <b>Notes:</b> {Orderdetails?.notes || 'No Notes'}
          </Text>
        </Box>

        {/* Price Summary */}
        <Box>
          <Flex justify="flex-end" mb={2}>
            <Box width="40%">
              <Flex justify="space-between">
                <Text fontWeight={'bold'}>Sub total:</Text>
                <Text>Rs {Orderdetails?.subtotal}</Text>
              </Flex>
              <Flex justify="space-between">
                <Text fontWeight={'bold'}>Delivery Fee:</Text>
                <Text>Rs {Orderdetails?.delivery}</Text>
              </Flex>
              {Orderdetails?.couponCode && (
                <>
                  <Flex justify="space-between">
                    <Text fontWeight={'bold'}>Before Discount:</Text>
                    <Text>
                      Rs {Orderdetails?.subtotal + Orderdetails?.delivery}
                    </Text>
                  </Flex>
                  <Flex justify="space-between">
                    <Text fontWeight={'bold'}>Coupon Code:</Text>
                    <Text>{Orderdetails?.couponCode}</Text>
                  </Flex>

                  <Flex justify="space-between">
                    <Text fontWeight={'bold'}>Coupon Discount:</Text>
                    <Text>Rs {Orderdetails?.discount}</Text>
                  </Flex>
                </>
              )}
              <Flex justify="space-between" fontWeight="bold">
                <Text>Total:</Text>
                <Text>Rs {Orderdetails?.total}</Text>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Box>

      {/* Print Button */}
      {/* <Flex justify="center" mt={5}>
        <Button colorScheme="orange">Print Invoice</Button>
      </Flex> */}
    </Box>
  );
};

export default Invoice;
